import React from "react"
import BackgroundImage from "gatsby-background-image"

const ContactHero = (props) => { 
  const sources = [
    props.data.contentfulContactPage.heroBackgroundImageMobile.fluid,
    {
      ...props.data.contentfulContactPage.heroBackgroundImageDesktop.fluid,
      media: `(min-width: 1024px)`,
    },
  ]

  return (
    <BackgroundImage fluid={sources}>
      <div className=" container mx-auto t-grid flex py-36 lg:py-64 lg:items-center">
        <h1 className="col-span-3 lg:col-start-1 lg:col-span-7 color-white ">
          GET IN TOUCH
        </h1>
      </div>
    </BackgroundImage>
  )
}

export default ContactHero

import React from "react"

import Layout from "../components/layout"

import SEO from "../components/seo"
import ContactHero from "../components/contacthero"
import ContactUs from "../components/contactus"
import { graphql } from "gatsby"

const ContactPage = ({data}) => {
  return (
  <Layout>
    <SEO title="Contact" />
    <ContactHero data={data}/>
    <ContactUs data={data}/>
  </Layout>
  ) 
}

export const query = graphql`
query contactPage {
  contentfulContactPage {
    buttonText
    contactCardTitle
    contactFormField1
    contactFormField2
    contactFormField3
    contactFormField4
    contactFormTitle
    contactInformation
    contactPageHeroTitle
    heroBackgroundImageDesktop {
      fluid(quality: 100) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    heroBackgroundImageMobile {
      fluid(quality:90) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    legalTextTitle
    legalTextParagraph {
      legalTextParagraph
    }
  }
}
`

export default ContactPage

import React from "react"

const LeftTitle = props => {
  return (
    <div className={"space-y-2 mt-0" + props.class}>
      <h5 className={props.color}>{props.text}</h5>
      <div className="orange-gradient line w-10" />
    </div>
  )
}

export default LeftTitle

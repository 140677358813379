import React from "react"
import LeftTitle from "./lefttitle"

const ContactUs = props => {
  const data = props.data.contentfulContactPage
  return (
    <div className="bg-black contact-us">
      <div className="contact px-7 lg:px-0 sm:container relative top-8 lg:-top-12 mx-auto t-grid flex flex-col lg:flex-row items-center pb-16 lg:pb-0 mb-0">
        <div className=" col-span-full lg:col-start-2 lg:col-span-4 flex flex-col lg:items-start pt-20 lg:py-20">
          <LeftTitle text={data.contactFormTitle} />
          <form
            name="contact"
            method="post"
            data-netlify="true"
            className="flex flex-col space-y-5 w-full my-8"
          >
            <input type="hidden" name="form-name" value="contact" />
            <input
              className="form-field body-1"
              type="text"
              name={data.contactFormField1}
              placeholder={data.contactFormField1}
              required
            />
            <input
              className="form-field body-1"
              type="text"
              name={data.contactFormField2}
              placeholder={data.contactFormField2}
              required
            />
            <input
              className="form-field body-1"
              type="email"
              name={data.contactFormField3}
              placeholder={data.contactFormField3}
              required
            />
            <textarea
              className="form-textarea h-36 body-1"
              rows="8"
              type="text"
              name={data.contactFormField4}
              placeholder={data.contactFormField4}
              required
            />
            <div>
              <input
                className="button-white cta mt-6 cursor-pointer"
                type="submit"
                value={data.buttonText}
              />
            </div>
          </form>
        </div>
        <div className="my-16 lg:my-0 lg:h-full col-span-full lg:col-span-2 flex justify-center">
          <div className="w-full lg:w-0 lg:h-full border-b lg:border-l border-solid dark-1-border"></div>
        </div>
        <div className="col-span-full h-full pb-6 lg:pt-20 lg:col-start-8 lg:col-span-4 2xl:col-start-8 2xl:col-span-3 space-y-5 flex flex-col">
          <h3>{data.contactCardTitle}</h3>
          {data.contactInformation.map(contact => (
            <p className="body-2">{contact}</p>
          ))}
          <br />
          <span className="eyebrow">{data.legalTextTitle}</span>
          <span className="body-3">{data.legalTextParagraph.legalTextParagraph}</span>
        </div>
      </div>
    </div>
  )
}

export default ContactUs
